 import Navbar from "../components/UI/Navbar"
import Section1 from '../components/Home/section1/section1'; 
import Section2 from '../components/Home/section2/section2'; 
import Section3 from '../components/Home/section3/section3';  

const Home = () => {
return (
    <>
    <Navbar button={true}/>
    <Section1 />
    <Section2 />
    <Section3 />
    </>
);
};

export default Home