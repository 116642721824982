// import Navbar2 from "../components/Login/Navbar/Navbar2"
import Navbar from "../components/UI/Navbar"
import SignupBody from "../components/Signup/body/signupBody"



const Signup = () => {
    return(
        <>
        <Navbar />
        <SignupBody />
        </>
    )
}

export default Signup;
