// import Navbar2 from "../components/Login/Navbar/Navbar2"
import Navbar from "../components/UI/Navbar"
import LoginBody from "../components/Login/body/loginBody"


const Login = () => {
    return(
        <>
        <Navbar />
        <LoginBody />
        </>
    )
}

export default Login;
