const firebaseKeys = {
    REACT_APP_API_KEY: "AIzaSyDA--8DK0BPdR0x3vLJ4mOU33QKPCYZ97g",
    REACT_APP_AUTH_DOMAIN: "doc-diff.firebaseapp.com",
    REACT_APP_PROJECT_ID: "doc-diff",
    REACT_APP_STORAGE_BUCKET: "doc-diff.appspot.com",
    REACT_APP_MESSAGING_SENDER_ID: "386647217720",
    REACT_APP_APP_ID: "1:386647217720:web:94c3603afbaa2ca6b2f8cd",
    REACT_APP_MEASUREMENT_ID: "G-8C15BZ2KWF",
    REACT_APP_CLIENT_ID: "386647217720-2e1rfd94535jcn2deetk5l47hrn1bqjq.apps.googleusercontent.com",
    REACT_APP_CLIENT_SECRET: "N50ElMGRCUCPZhxODlhVp7IT",
}

export default firebaseKeys;