// import Navbar3 from "../components/Projects/Navbar/Navbar3"
import Navbar from "../components/UI/Navbar"
import Section4 from '../components/Projects/Section4/body'; 

const Projects = () => {
return(
    <>
    <Navbar button={true} projects={true}/>
    <Section4/>
    </>
 )
};

export default Projects
